.container {
  display: flex;
  justify-content: space-around;
  /* width: 75vw; */
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-bottom: 4rem;
  padding: 2rem;
  background-color: #f9f9f9;
  border: 0.1rem solid #b0b0b0;
  border-radius: 0.5rem;
}
/* .row {
  display: flex;
  justify-content: space-between; 
  flex-wrap: wrap; 
  width: 100%; 
} */
.card-body {
  overflow: hidden;
  text-overflow: ellipsis;
}

.container1 {
  padding: 2rem;
  text-align: center;
  background-color: #f9f9f9;
  margin-bottom: 2rem;
  border: 0.1rem solid #b0b0b0;
  border-radius: 0.5rem;
}

.overall {
  margin: 0 auto;
  width: 70vw;
  margin-top: 12vh;
  border-radius: 0.5rem;
}

h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-top: 3rem;
  color: #333;
}

.card-title a {
  margin-top: 2rem;
  text-decoration: none;
  color: inherit;
}

.card-title a:hover {
  text-decoration: underline;
  text-decoration-color: white;
}

.custom-underline {
  text-decoration: underline;
  text-decoration-color: white;
  text-decoration-thickness: 0.2rem;
}

.card {
  height: 30rem;
  margin: 2rem auto;
  border: 0.1rem solid #b0b0b0;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
  text-align: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: calc(100% - 2rem);
  max-width: 18rem;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.btn {
  margin: 2rem 0;
}

.dark-mode .container, .dark-mode .card, .dark-mode .container1 {
  background-color: #333333;
  border-color: #888888;
}

.dark-mode h1, .dark-mode .card-title, .dark-mode p {
  color: #ffffff;
}

.card img {
  border-radius: 0.3rem;
  object-fit: cover;
  max-height: 12rem;
  width: 100%;
}

@media (max-width: 1200px) {
  .overall {
    width: 90vw;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  .card-title {
    font-size: 1.1rem;
  }
  .container {
    display: flex;
    /* padding: vw; */
    flex-direction: column;
    align-items:center;
    width: 85vw;
    margin-top: 3vh;
  }
  .card {
    margin: 1rem -1.1rem;
    width: 100%;
  }
}
@media (max-width: 820px) {
  h1 {
    font-size: 2rem;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .container {
    display: flex;
    /* padding: vw; */
    flex-direction: column;
    align-items:center;
    width: 85vw;
    margin-top: 3vh;
  }
  .card {
    margin: 1rem -1.1rem;
    width: 100%;
  }
}
@media (max-width: 912px) {
  h1 {
    font-size: 2rem;
  }
  .card-title {
    font-size: 1.2rem;
  }

  .container {
    display: flex;
    /* padding: vw; */
    flex-direction: column;
    align-items:center;
    width: 85vw;
    margin-top: 3vh;
  }
  .card {
    margin: 1rem -1.2rem;
    width: 100%;
  }
}
@media (max-width: 540px) {
  h1 {
    font-size: 2rem;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .row {
    flex-direction: column;
    align-items: center; 
    margin-right: -1vw;
  }
  .container{
   margin-top: 3vh;
    width: 85vw;
  }
}
@media (max-width: 344px) {
  h1 {
    font-size: 2rem;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .card{
    width: 15rem !important
  }
  .row {
    flex-direction: column;
    align-items: center; 
    margin-right: 20vw;
  }
  .container{
   margin-top: 3vh;
    width: 85vw;
  }
}


@media (max-width: 390px) {
  h1 {
    font-size: 2rem;
  }
  .card-title {
    font-size: 1.2rem;
  }
  .card{
    width: 15rem !important
  }
  .row {
    flex-direction: column;
    align-items: center; 
    margin-right: 27vw;
  }
  .container{
   margin-top: 3vh;
    width: 95vw;
  }
}


@media (max-width: 576px) {
  h1 {
    font-size: 1.5rem;
  }
  .card {
    height: auto;
    width: 100%;
  }
  .card img {
    max-height: 10rem;
  }
  .btn {
    font-size: 0.9rem;
  }
}
body{
  scroll-behavior: smooth !important;
}