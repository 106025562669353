.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;          
  max-width: 75%;             
  max-height: 70vh;           
  border: 2px solid #ccc;     
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
  overflow: hidden;           
  margin: 13vh auto 0; 
  background-color: #36454F;
  border-color: #36454F;
}

.dark-mode .carousel-container {
  margin-top: 0;
  margin-right: auto; /* Ensures centering */
  margin-left: auto;  /* Ensures centering */
}

.carousel-image {
  width: 100%;               
  height: 100%;              
  object-fit: contain;        
  object-position: center;    
}

@media (max-width: 768px) {
  .carousel-container {
      max-width: 90%;  
      margin: 5vh auto; 
  }
}

@media (max-width: 480px) {
  .carousel-container {
      max-width: 95%; 
      margin: 3vh auto;
  }
}

body {
  scroll-behavior: smooth !important;
}
