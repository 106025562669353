.card-rag {
  margin-top: 15vh;
}

.card-body_rag {
  margin-left: 33vh;
  width: 70%;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  margin-bottom: 30px;
  border: 2px solid #b0b0b0;
  border-radius: 7px;
}
p{
  font-family: 'Times New Roman', serif;
  font-size:x-large;
  font-weight: 500;
  /* font-style: italic; */
}

img {
  max-width: 100%;
  height: auto;
}

.card-body_rag img {
  display: block;
  margin: 0 auto; 
}

@media (max-width: 768px) {
  .card-body_rag {
      margin-left: 5vh;
      width: 90%;
  }
  .card-body_rag img {
      width: 90%;
  }
}

@media (max-width: 480px) {
  .card-body_rag {
      margin-left: 1.2vh;
      width: 95%;
  }
  .card-body_rag img {
      width: 95%;
  }
}

.Book_icon {
  margin-left:47vw;
  margin-bottom: 5vh;
  color: #6495ED;
}


@media (max-width: 768px) {
  .Book_icon {
      margin-left: 43vw;
  }
  .card-body_rag iframe {
    width: 100%;
}

}

@media (max-width: 480px) {
  .Book_icon {
      margin-left: 39vw;
  }
  .card-body_rag iframe {
    width: 100%; 
}
}


.dark-mode .card-body_rag {
  background-color: #333333;
  border-color: #888888;
}

.dark-mode h1, .dark-mode .card-body_rag, .dark-mode p {
  color: #ffffff;
}
.card-body_rag iframe {
  display: block;
  margin: 0 auto; 
  max-width: 100%;
  height: auto;
  border: none; 
  aspect-ratio: 16/9; 
}

.card-body_rag p{
  font-family: 'Times New Roman', serif;
  font-size:larger;
  font-weight: 500;
  /* font-style: italic; */
} 
.card-body_rag h1{
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;

}
.card-body_rag h3{
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;

}
.move-top-btn {
  position: fixed;  
  border: 2px solid lightblue;      
  bottom: 20px;          
  right: 10px;           
  background-color: #F0FFFF; 
  color: black;           
  padding: 10px 15px;    
  border: none;          
  border-radius: 50px;   
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  font-size: 14px;       
  cursor: pointer;       
  z-index: 1000;         
}

.move-top-btn:hover {
  background-color: #0096FF; 
  color:white;
}
.card-rag h3{
  text-align: left;
}
.card-rag h5{
  text-align: left;
}
.card-rag p {
  text-align: left;
}
body{
  scroll-behavior: smooth !important;
}