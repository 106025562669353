.container_card_item {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4vh;
  flex-wrap: wrap;
  margin-top: 4vh;
}
.gif {
  margin-left: 5vw;
}
.img-dark {
  background-color: white;
  cursor: default;
}
@media (prefers-color-scheme: dark) {
  .img-dark {
    background-color: #3A3A3A;
    padding: 1rem;
  }
  body {
    background-color: #3A3A3A;
  }
}
.heading h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
}
.heading {
  color: lightblue;
}
.dark-mode, .dark-mode .card_item {
  background-color: #333333;
  border-color: #888888;
}
.dark-mode h1, .dark-mode .card-title, .dark-mode p {
  color: #ffffff;
}
.card_item-title {
  text-align: center;
}
.card_item:hover {
  transform: scale(1.05);
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.2);
}
.card_item img {
  border-radius: 0.5rem;
  object-fit: cover;
  max-height: 20rem;
}
.card_item {
  margin-bottom: 2vh;
  margin-top: 4vh;
  border: 0.2rem solid #b0b0b0;
  border-radius: 0.7rem;
  background-color: #f9f9f9;
  text-align: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
h1 {
  text-align: center;
}
.heading {
  margin-top: 8vh;
  margin-bottom: 8vh;
}
h5, img {
  cursor: pointer;
}
.move-top-btn {
  position: fixed;
  border: 0.2rem solid lightblue;
  bottom: 2vh;
  right: 1vw;
  background-color: #F0FFFF;
  color: black;
  padding: 1rem 1.5rem;
  border-radius: 5rem;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.2);
  font-size: 1.4rem;
  cursor: pointer;
  z-index: 1000;
}
.move-top-btn:hover {
  background-color: #0096FF;
  color: white;
}
.dark-mode .img-dark {
  background-color: #3A3A3A;
}
.gif {
  margin-bottom: 20vh;
}
.full_Card_item_container {
  background-color: white;
}
.dark-mode .full_Card_item_container {
  background-color: #3A3A3A;
  transition: background-color 0.3s ease;
}
@media only screen and (max-width: 600px) {
  .container_card_item {
    flex-direction: column;
    align-items: center;
  }
  .card_item {
    width: 90%;
    margin-bottom: 3vh;
    margin-top: 2vh;
  }
  .gif {
    display: flex;
  }
  h1 {
    font-size: 2.4rem;
  }
  .move-top-btn {
    bottom: 1vh;
    right: 0.5vw;
    padding: 0.8rem 1.2rem;
    font-size: 1.2rem;
  }
  .gif img:first-child {
    margin-right: 2rem;
  }
}
.gif {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.gif img {
  width: 30%;
  height: auto;
  margin-left: -1vw;
}
.gif img:first-child {
  margin-left: 0;
}
@media (max-width: 1200px) {
  .gif img {
    width: 40%;
    margin-left: -1vw;
  }
}
@media (max-width: 768px) {
  .gif {
    flex-direction: column;
    align-items: center;
  }
  .gif img {
    width: 90%;
    margin-left: 0;
    margin-bottom: 2rem;
  }
}
.full_Card_item_container{
  background-color: #66B2FF;
}
body{
  /* overflow-x: hidden; */
  scroll-behavior: smooth !important;
}
