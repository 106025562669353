.navbar {
    background-color: white;
    color: #333;
    transition: background-color 0.3s ease; 
}
.nav-item {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}
.dark-mode .navbar {
    background-color: #3A3A3A; 
    color: white;
}
.navbar-nav {
    display: flex; 
    width: 100%; 
}
.container_card_item {
    margin-bottom: 5vh;
}
.nav-link {
    color: #333; 
    transition: color 0.3s ease; 
    padding: 10px 15px; 
}
.nav-link:hover {
    color: lightblue; 
}
.dark-mode .nav-link {
    color: white; 
}
.dark-mode .nav-link:hover {
    color: lightblue; 
}
.logo {
    width: 170px;
    margin-right: 20px; 
    border-radius: 20px;
    border: 2px solid lightblue; 
}
.contact-link {
    margin-left: 47rem !important; 
    margin-top: 0.19vh;
    border: 2px solid lightblue; 
    border-radius: 20px; 
    padding: 5px 10px; 
    transition: background-color 0.3s ease, color 0.3s ease; 
}
.contact {
    margin-left: auto; 
}
.form-check-input:checked {
    background-color: lightblue;
}
@media (max-width: 768px) {
    .navbar-nav {
        flex-direction: column; 
        width: 100%; 
    }
}
@media (max-width: 1280px) {
    .contact-link {
        margin-left: 33rem !important;
    }
}
/* @media (min-width: 1281px) {
    .contact-link {
        margin-left: 37rem !important;
    }
} */
@media (max-width: 1024px) {
    .contact-link {
        margin-left: 15rem !important;
    }
}
@media (max-width: 360px) {
    .navbar {
        margin-bottom: 5vw;
    }
}
@media (max-width: 768px) {
    .navbar {
        margin-bottom: 5vw;
    }
}
.navbar-toggler {
    border-color: lightblue;
}
.navbar-toggler:focus {
    outline: none; 
    box-shadow: none; 
}
.nav-item + .nav-item {
    margin-left: 20px; 
}
.contact-link:hover {
    text-decoration: none; 
    background-color: #0096FF; 
    color: white; 
}
.contact-link:focus {
    outline: none; 
}
.form-check {
    margin-top: 1.45vh;
}
body{
    scroll-behavior: smooth !important;
  }