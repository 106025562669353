
/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
.footer {
  background-color: #36454F;
  color: white; 
  position: relative;
  width: 100% !important;
  overflow-x: hidden !important;
}
.seperate{
  width: 100vw;
}
.footer h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
  margin-bottom: 1rem;
}

.footer p {
  font-family: 'Times New Roman', serif;
  font-size: large;
  font-weight: 500;
}

@media (max-width: 768px) {
  .footer {
      width: 100% !important;
      overflow-x: hidden !important;
  }
}  
@media (max-width: 375px) {
  .footer {
      width: 100% !important;
      overflow-x: hidden !important;
  }
} 
@media (max-width: 576px) {
  .footer {
      width: 100% !important;
      overflow-x: hidden !important;
  }
}

.contact-info p {
  margin: 0.5rem 0; 
}

.social-icons {
  margin-top: 1rem; 
}

.social-icons a {
  margin: 0 10px;
  color: white; 
  transition: color 0.3s; 
}

.social-icons a:hover {
  color: #FFC107; 
}

body {
  scroll-behavior: smooth;
}
#footer-section
{
  overflow-x: hidden;
}
