
.container_blog {
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  padding: 10px; 
  flex-wrap: wrap; 
  margin-left: 5vw;
}

.card_blog {
  margin-bottom: 5vh;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;  
  max-width: 400px; 
}

.card_blog:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.card-body {
  padding: 20px;
}

.btn {
  margin-top: 20px;
}

.card-img-top {
  border-radius: 5px;
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.search_bar {
  border-radius: 20px;
  margin-top: 12vh; 
  height: 50px;
  width: 70%; 
  margin-left: 5vw; 
  margin-right: 10vw; 
  display: block; 
  margin-bottom: 10vh;
}

.dark-mode .card-body, .dark-mode .card_blog {
  background-color: #333333;
  border-color: #888888;
}

.dark-mode .card-title {
  color: #ffffff;
}

@media (max-width: 576px) {
  .container_blog {
    padding: 10px;
    margin-left: 7vw;
  }

  .search_bar {
    width: 70%;
    margin-right: 5vw; 
  }

  .card_blog {
    margin: 10px 0;
    width: 90%;  
  }

  .card-img-top {
    height: 120px;  
  }
}

@media (max-width: 768px) {
  .search_bar {
    width: 70%; 
    margin-right: 10vw; 
  }
  .container_blog {
    padding: 10px;
    margin-left: 7vw;
  }
  .row{
    display: flex;
    flex-direction: column;
  }

  .card_blog {
    margin: 20px;
    width: calc(50% - 40px);
  }

  .card-img-top {
    height: 180px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .search_bar {
    width: 70%; 
    margin-right: 15vw; 
  }
  .container_blog {
    padding: 10px;
    margin-left: 7vw;
    display:block
  }
  .row{
    display: flex;
    flex-direction: column;
  }
}
/* @media (max-width: 1024px) {
  .container_blog{
    margin-right: 40vw;
    margin-left: 10vw;
  }
  .card_blog{
    margin-right: 37vw !important;
  }
} */

.move-top-btn {
  position: fixed;  
  border: 2px solid lightblue;      
  bottom: 20px;          
  right: 10px;           
  background-color: #F0FFFF; 
  color: black;           
  padding: 10px 15px;    
  border: none;          
  border-radius: 50px;   
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  font-size: 14px;       
  cursor: pointer;       
  z-index: 1000;         
}

.move-top-btn:hover {
  background-color: #0096FF; 
  color: white;
}
body{
  scroll-behavior: smooth !important;
}